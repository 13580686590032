.gteviz-footer {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #7a7a7a;
  padding-top: 20px;
  padding-bottom: 30px; }

.gteviz-svgviz {
  position: relative;
  display: block;
  width: 40%;
  float: left; }
  @media (min-width: 768px) {
    .gteviz-svgviz {
      with: 50%; } }
  .gteviz-svgviz__wrapper svg path.domain {
    display: none; }
  .gteviz-svgviz__wrapper svg .tick text {
    color: #7a7a7a; }
  .gteviz-svgviz__wrapper svg .tick line {
    stroke: #7a7a7a; }
  .gteviz-svgviz__wrapper svg rect {
    fill-opacity: 1;
    stroke: white;
    stroke-width: 2;
    -moz-transition: fill-opacity 0.5s;
    -o-transition: fill-opacity 0.5s;
    -webkit-transition: fill-opacity 0.5s; }
    .gteviz-svgviz__wrapper svg rect:hover {
      fill-opacity: 0.9; }
  .gteviz-svgviz__tooltip {
    position: absolute;
    color: #ff5064;
    font-size: 12px;
    text-align: center;
    font-weight: bold; }
  .gteviz-svgviz__intro {
    position: absolute;
    top: 50px;
    right: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11);
    color: black;
    font-size: 12px;
    line-height: 1.4;
    text-align: center; }
    @media (min-width: 768px) {
      .gteviz-svgviz__intro {
        left: 40px;
        right: 40px;
        font-size: 14px; } }
    .gteviz-svgviz__intro .fa {
      display: block; }

.gteviz-dataselector {
  display: block;
  width: 60%;
  position: absolute;
  bottom: 25px;
  left: 40%; }
  @media (min-width: 768px) {
    .gteviz-dataselector {
      width: 50%;
      left: 50%; } }

.gteviz-dataitem {
  margin: 0 3px 3px 0;
  width: 100%;
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #7a7a7a;
  border-radius: 10px;
  padding: 5px 7px;
  font-size: 12px;
  line-height: 1;
  color: black;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s; }
  @media (min-width: 768px) {
    .gteviz-dataitem {
      font-size: 14px; } }
  .gteviz-dataitem:hover {
    -moz-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    background-color: #ffe9ec; }
  .gteviz-dataitem--active {
    color: white;
    background-color: #ff5064;
    border-color: #ff5064; }
    .gteviz-dataitem--active:hover {
      color: white;
      background-color: #ff5064; }
  .gteviz-dataitem__close {
    position: absolute;
    right: 10px;
    color: white; }
    .gteviz-dataitem__close:after {
      content: '\2716'; }

.gteviz-infobox {
  display: none; }
  .gteviz-infobox--visible {
    line-height: 24px;
    font-size: 14px;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    text-align: center;
    display: block;
    position: absolute;
    top: 80px;
    z-index: 100;
    background-color: white;
    right: 40px;
    left: 40px;
    visibility: visible;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11);
    padding: 20px 0 20px 0; }
    @media (min-width: 768px) {
      .gteviz-infobox--visible {
        top: auto;
        left: 110px;
        right: 50%;
        bottom: 30px;
        margin-right: 10px; } }
  @media (min-width: 768px) {
    .gteviz-infobox--visible-lg {
      line-height: 24px;
      font-size: 14px;
      border: 1px solid #f5f5f5;
      border-radius: 10px;
      text-align: center;
      display: block;
      position: absolute;
      top: 80px;
      z-index: 100;
      background-color: white;
      right: 40px;
      left: 40px;
      visibility: visible;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11);
      padding: 20px 0 20px 0; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .gteviz-infobox--visible-lg {
      top: auto;
      left: 110px;
      right: 50%;
      bottom: 30px;
      margin-right: 10px; } }
  .gteviz-infobox__close {
    font-size: 26px;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #1e4664; }
    .gteviz-infobox__close:before {
      content: '\2716'; }
    @media (min-width: 768px) {
      .gteviz-infobox__close {
        font-size: 14px; } }
  .gteviz-infobox__value {
    font-size: 30px !important;
    padding: 10px;
    margin-bottom: 0 !important;
    display: block;
    width: 100%;
    color: #1e4664;
    font-weight: bold; }
  .gteviz-infobox__title {
    margin-bottom: 0 !important;
    padding: 10px;
    font-size: 14px !important;
    line-height: 20px !important; }
  .gteviz-infobox__text, .gteviz-infobox__source {
    line-height: 20px !important;
    padding: 10px !important;
    margin: 0 !important; }

.gteviz-targetmessage {
  display: none;
  visibility: hidden;
  z-index: -99; }
  .gteviz-targetmessage--visible {
    text-align: center;
    padding: 10px;
    line-height: 24px;
    font-size: 14px;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 80px;
    z-index: 100;
    background-color: white;
    right: 30px;
    left: 30px;
    visibility: visible;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11); }
    @media (min-width: 768px) {
      .gteviz-targetmessage--visible {
        left: 20%;
        right: 20%; } }
  .gteviz-targetmessage__close {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white; }
    .gteviz-targetmessage__close:before {
      content: '\2716'; }
  .gteviz-targetmessage__title {
    display: block;
    width: 100%;
    color: #ff5064;
    font-weight: bold; }
  .gteviz-targetmessage__text {
    font-size: 14px; }
    .gteviz-targetmessage__text p {
      margin: 0;
      padding-bottom: 10px; }
  .gteviz-targetmessage .button {
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    padding: 8px;
    margin-top: 10px;
    font-weight: normal;
    -moz-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s; }
    @media (min-width: 768px) {
      .gteviz-targetmessage .button {
        font-size: 14px; } }
    .gteviz-targetmessage .button:hover {
      background-color: #ff8391; }
    .gteviz-targetmessage .button--light {
      border: 1px solid #ff5064;
      background-color: white;
      color: #ff5064; }
      .gteviz-targetmessage .button--light:hover {
        background-color: #ffe9ec; }

.gteviz-wrapper {
  font-family: "Source Sans Pro", sans-serif;
  display: block;
  width: 100%;
  position: relative; }
  .gteviz-wrapper .gteviz-inner {
    display: block;
    position: relative; }
    .gteviz-wrapper .gteviz-inner:after {
      display: block;
      content: '';
      clear: both; }
  .gteviz-wrapper .gteviz-anchor {
    display: block;
    height: 0; }
